
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-black.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-black-italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-bold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-bold-italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-medium-italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-book.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'circular-std';
  src: url('../assets/fonts/circular-std/circular-std-book-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
