.svg-icon {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  display: inline-block;
  background-repeat: no-repeat;

  &.svg-icon-button {
  }

  &.icon-investigate {
    background-image: url('../assets/icons/investigate.svg');
  }

  &.icon-hotel-bell {
    background-image: url('../assets/icons/hotel-bell.svg');
  }

  &.icon-do-not-disturb {
    background-image: url('../assets/icons/do-not-disturb.svg');
  }

  &.icon-box {
    background-image: url('../assets/icons/box.svg');
  }

  &.icon-import {
    background-image: url('../assets/icons/export.svg');
  }

  &.icon-export {
    background-image: url('../assets/icons/import.svg');
  }

  &.icon-import-data {
    background-image: url('../assets/icons/import-data.svg');
  }

  &.icon-export-data {
    background-image: url('../assets/icons/export-data.svg');
  }

  &.icon-user-group {
    background-image: url('../assets/icons/user-groups.svg');
  }

  &.icon-user-subgroup {
    background-image: url('../assets/icons/user-sub-groups.svg');
  }

  &.icon-floor {
    background-image: url('../assets/icons/construction.svg');
  }

  &.icon-room-category {
    background-image: url('../assets/icons/room-category.svg');
  }

  &.icon-building {
    background-image: url('../assets/icons/building.svg');
  }

  &.icon-hotel {
    background-image: url('../assets/icons/hotel.svg');
  }

  &.icon-sweeping {
    background-image: url('../assets/icons/sweeping.svg');
  }

  &.icon-vacuum {
    background-image: url('../assets/icons/vacuum.svg');
  }

  &.icon-certificate {
    background-image: url('../assets/icons/certificate.svg');
  }

  &.icon-management {
    background-image: url('../assets/icons/management.svg');
  }

  &.icon-room-key {
    background-image: url('../assets/icons/room_key.svg');
  }

  &.icon-rule {
    background-image: url('../assets/icons/rule.svg');
  }

  &.icon-sofa {
    background-image: url('../assets/icons/sofa.svg');
  }

  &.icon-tasks {
    background-image: url('../assets/icons/tasks.svg');
  }

  &.icon-worker {
    background-image: url('../assets/icons/worker.svg');
  }

  &.icon-database {
    background-image: url('../assets/icons/database.svg');
  }

  &.icon-trash-red {
    background-image: url('../assets/icons/trash-red.svg');
  }

  &.icon-pen-red {
    background-image: url('../assets/icons/pen-red.svg');
  }

  &.icon-collapse-black {
    background-image: url('../assets/icons/collapse-black.svg');
  }

  &.icon-expand-black {
    background-image: url('../assets/icons/expand-black.svg');
  }


  &.icon-back-black {
    background-image: url('../assets/icons/back-black.svg');
  }

  &.icon-check-black {
    background-image: url('../assets/icons/check-black.svg');
  }

  &.icon-close-black {
    background-image: url('../assets/icons/close-black.svg');
  }

  &.icon-back-red {
    background-image: url('../assets/icons/back-red.svg');
  }

  &.icon-check-red {
    background-image: url('../assets/icons/check-red.svg');
  }

  &.icon-close-red {
    background-image: url('../assets/icons/close-red.svg');
  }

  &.icon-roles {
    background-image: url('../assets/icons/security-groups.svg');
  }

  &.icon-cleaning {
    background-image: url('../assets/icons/cleaning.svg');
  }

  &.icon-hotel {
    background-image: url('../assets/icons/hotel.svg');
  }
}
